<template>
  <div
    class="mx-5 mb-5"
  >
    <v-btn-toggle
      v-model="attachmentTypeId"
      class="d-flex align-center justify-center"
    >
      <btn
        v-for="bucket in bucketTypes"
        :key="bucket.value"
        :label="bucket.title"
        :icon="bucket.icon"
        :value="bucket.value"
        :style="`width:${100 / bucketTypes.length}%`"
        :disabled="disabled"
        @click="attachmentTypeClicked(bucket.value)"
      >
        <v-badge
          v-if="attachmentCount(bucket.value)"
          color="primary"
          :content="attachmentCount(bucket.value)"
          inline
        >
          {{ bucket.title }}
        </v-badge>
      </btn>
    </v-btn-toggle>

    <!-- attachment modal -->
    <attachment-modal
      :attachment-modal.sync="attachmentModal"
      :attachment-type-id.sync="attachmentTypeId"
      :attachment-type.sync="attachmentType"
      :encounter-attachments.sync="encounterAttachments"
      :patient-id="patientId"
      :signed="isSigned"
    ></attachment-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AttachmentModal from '@/components/notes/encounter/AttachmentModal.vue'

export default {
  components: { AttachmentModal },
  props: {
    picsOnly: {
      type: Boolean,
      default: false,
    },
    isSigned: {
      type: [Boolean, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    patientId: {
      type: String,
      default: null,
    },
    encounterAttachments: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      attachmentTypeId: null,
      attachmentModal: false,
      attachmentType: null,
    }
  },
  computed: {
    ...mapGetters('baseData', ['bucketTypes']),
  },
  watch: {
  },
  methods: {
    attachmentCount(bucket) {
      if (!Array.isArray(this.encounterAttachments)) return false

      return this.encounterAttachments.reduce((count, item) => count + (item.attachment_type === bucket), 0)
    },
    attachmentTypeClicked(value) {
      this.attachmentType = value
      this.attachmentTypeId = null
      this.attachmentModal = true
    },
  },
}
</script>
