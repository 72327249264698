<template>
  <v-dialog
    v-if="dialogAttachmentType !== null"
    v-model="modalState"
    width="800"
    content-class="attachments-modal"
    persistent
    @click:outside="clickOutside()"
  >
    <v-card
      class="pb-5"
      style="min-height: 675px"
    >
      <!-- add attachment header -->
      <v-card-title
        v-if="!isFileSelected"
        class="pb-5"
      >
        <!-- close -->
        <btn
          label="Close"
          :icon="icons.mdiClose"
          color="secondary"
          @click="closeModal"
        ></btn>
        <v-spacer></v-spacer>
        Attachments
        <v-spacer></v-spacer>

        <!-- select attachment -->
        <btn
          label="Select Attachment"
          :icon="$store.getters['baseData/bucketTypeFromValue'](dialogAttachmentType).icon"
          :disabled="signed && dialogAttachmentType === 'images'"
          @click="selectAttachmentClicked"
        ></btn>
        <input
          ref="uploader"
          type="file"
          class="d-none"
          @change="onFileChanged"
        />
      </v-card-title>

      <!-- upload attachment header -->
      <v-form
        v-else
        ref="uploadForm"
        v-model="formValid"
        @submit.prevent="addFile"
      >
        <v-card-title class="pb-5">
          <!-- cancel -->
          <btn
            label="Cancel"
            :icon="icons.mdiClose"
            color="secondary"
            @click="cancelUpload(false)"
          ></btn>

          <v-spacer></v-spacer>

          <!-- name file -->
          <text-field
            v-model="fileDescription"
            label="Attachment Description"
            required
            style="margin-bottom: -25px"
          ></text-field>

          <v-spacer></v-spacer>

          <img
            v-if="isImageType(attachment.attachment_mime_type)"
            :src="imagePreview"
            class="image-preview"
          >
          <div
            v-else
            class="image-preview mime-type"
          >
            {{ mimeType(attachment.attachment_mime_type) }}
          </div>

          <v-spacer></v-spacer>

          <!-- add file -->
          <btn
            label="Add File"
            :disabled="!formValid"
            :icon="icons.mdiFileImagePlus"
            :loading="isAddingFile"
            @click="addFile"
          ></btn>
        </v-card-title>
      </v-form>

      <!-- attachment type list -->
      <v-card-text>
        <v-btn-toggle
          v-model="dialogAttachmentTypeId"
          class="d-flex align-center justify-center"
        >
          <btn
            v-for="bucket in bucketTypes"
            :key="bucket.value"
            :disabled="isFileSelected"
            :label="bucket.title"
            :icon="bucket.icon"
            :value="bucket.value"
            :style="`width:${100 / bucketTypes.length}%`"
            :color="dialogAttachmentType === bucket.value ? 'success' : '#ddd'"
            @click="attachmentTypeClicked(bucket)"
          >
            <v-badge
              v-if="attachmentCount(bucket.value)"
              color="success"
              :content="attachmentCount(bucket.value)"
              inline
            >
              {{ bucket.title }}
            </v-badge>
          </btn>
        </v-btn-toggle>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="currentAttachmentsArr()"
        :class="isFileSelected && 'disable'"
        item-key="id"
        sort-by="created"
        :sort-desc="true"
        disable-pagination
        hide-default-footer
        @click:row="viewAttachment"
      >
        <!-- image -->
        <template #item.data="{ item }">
          <v-img
            v-if="isImageType(item.attachment_mime_type) && item.data !== 'placeholder'"
            :lazy-src="item.data"
            :src="item.data"
            max-width="250"
            max-height="150"
          ></v-img>
          <pdf
            v-else-if="isPDFType(item.attachment_mime_type) && item.data !== 'placeholder'"
            :src="item.data"
            :page="1"
            class="pdf-preview"
          ></pdf>
          <btn
            v-else-if="item.data === 'placeholder'"
            label="Click to view attachment"
            color="primary"
            offline-disable
          ></btn>
          <!--
          <div
            v-else-if="isPDFType(item.attachment_mime_type)"
            class="table-preview"
          >
            <v-icon x-large>
              {{ icons.mdiFilePdfBox }}
            </v-icon>
          </div>
          -->
          <div
            v-else
            class="table-preview"
          >
            {{ mimeType(item.attachment_mime_type) }}
          </div>
        </template>

        <!-- created date/time -->
        <template #item.created="{ item }">
          {{ formatDateTime(item.created) }}
        </template>

        <!-- size -->
        <template #item.size="{ item }">
          {{ Math.round(item.data.length / 1024) }}KB
        </template>

        <!-- delete -->
        <template #item.updated="{ item }">
          <v-icon
            v-if="item.updated"
            right
            color="error"
            @click.stop="deleteAttachment(null, item)"
          >
            {{ icons.mdiTrashCan }}
          </v-icon>
          <v-icon
            v-else
            right
            color="success"
            @click.stop="viewAttachment(item)"
          >
            {{ icons.mdiCloudCheckVariant }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-overlay
      v-if="showOverlay"
      rem:value="showOverlay"
    >
      <v-card
        class="attachment-overlay"
      >
        <v-img
          v-if="isImage"
          :src="currentAttachmentsArr()[imageViewIndex].data"
          contain
        >
          <template #placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
              style="flex-direction: column"
            >
              <v-progress-circular
                indeterminate
                size="64"
                width="7"
                color="primary"
                class="load-sync-progress"
              ></v-progress-circular>
              <p
                class="loading-message"
                style="color: var(--v-accent-lighten3)"
              >
                Loading...
              </p>
            </v-row>
          </template>
        </v-img>
        <v-row
          v-if="isPDF && loadingPDF"
          class="fill-height ma-0"
          align="center"
          justify="center"
          style="flex-direction: column"
        >
          <v-progress-circular
            indeterminate
            size="64"
            width="7"
            color="primary"
            class="load-sync-progress"
          ></v-progress-circular>
          <p
            class="loading-message"
            style="color: var(--v-accent-lighten3)"
          >
            Loading...
          </p>
        </v-row>
        <div v-if="isPDF">
          <pdf
            v-for="i in pagesPDF"
            :key="i"
            :src="srcPDF"
            :page="i"
            class="pdf-viewer"
            @loaded="pageLoaded(i)"
          ></pdf>
        </div>
        <v-row
          align="center"
          justify="space-between"
          class="controls"
        >
          <btn
            label="Close"
            color="secondary"
            :icon="icons.mdiClose"
            @click="closeOverlay"
          ></btn>
          <div class="attachment-title">
            {{ imageTitle }}
          </div>
          <div>
            {{ formatDateTime(imageDate) }}
          </div>
          <btn
            v-if="currentAttachmentsArr()[imageViewIndex].updated"
            label="Delete"
            :icon="icons.mdiTrashCan"
            color="error"
            @click="deleteAttachment(imageViewIndex)"
          ></btn>
          <div
            v-if="currentAttachmentsArr()[imageViewIndex].updated !== true"
            class="synced"
          >
            <v-icon
              color="success"
              class="mr-1"
            >
              {{ icons.mdiCloudCheckVariant }}
            </v-icon>
            Synced
          </div>
        </v-row>
      </v-card>
    </v-overlay>
  </v-dialog>
</template>

<script>
/* eslint-disable no-param-reassign */

import {
  mdiClose, mdiFileImagePlus, mdiTrashCan, mdiCloudCheckVariant, mdiFilePdfBox, mdiDownload,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import pdf from '@teckel/vue-pdf'

export default {
  components: { pdf },
  props: {
    attachmentModal: {
      type: Boolean,
      default: false,
    },
    attachmentTypeId: {
      type: [Number, String],
      default: null,
    },
    attachmentType: {
      type: String,
      default: null,
    },
    signed: {
      type: Boolean,
      default: false,
    },
    patientId: {
      type: String,
      default: null,
    },
    encounterAttachments: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      srcPDF: null,
      pagesPDF: 1,
      loadingPDF: true,
      formValid: false,
      modalState: false,
      showOverlay: false,
      isImage: false,
      isPDF: false,
      imageViewIndex: null,
      imageTitle: null,
      imageDate: null,
      dialogAttachmentCreate: false,
      dialogAttachmentTypeId: null,
      dialogAttachmentType: null,
      encounterId: this.$route.query.id,
      isFileSelected: false,
      isAddingFile: false,
      selectedFile: null,
      file: [],
      attachment: {
        attachment_filename: null,
        attachment_mime_type: null,
        attachment_filepath: '',
        attachment_width: 0,
        attachment_height: 0,
        data: null,
      },
      downloadedAttachments: [],
      fileDescription: null,
      imagePreview: null,
      icons: {
        mdiClose, mdiFileImagePlus, mdiTrashCan, mdiCloudCheckVariant, mdiFilePdfBox, mdiDownload,
      },
      headers: [
        {
          text: 'Image',
          value: 'data',
          align: 'center',
          sortable: false,
          width: 250,
          filter: value => !this.data || value === this.data,
        },
        {
          text: 'Description',
          value: 'attachment_filename',
          align: 'center',
          width: 200,
        },
        {
          text: 'Created',
          value: 'created',
          align: 'center',
        },
        {
          text: 'Size',
          value: 'size',
          sortable: false,
        },
        {
          text: 'Synced',
          value: 'updated',
          align: 'small',
        },
        {
          text: 'Bucket',
          value: 'attachment_type',
          align: 'hide',
          filter: value => !this.dialogAttachmentType || value === this.dialogAttachmentType,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('baseData', ['bucketTypes']),
    ...mapGetters(['isOnline']),
  },
  watch: {
    attachmentModal() {
      this.modalState = this.attachmentModal
      this.dialogAttachmentTypeId = Number(this.attachmentTypeId)
      this.$emit('update:attachmentTypeId', null)
    },
    attachmentType() {
      this.dialogAttachmentType = this.attachmentType
    },
    showOverlay() {
      this.loadingPDF = true
      if (this.showOverlay && this.isPDF) {
        this.srcPDF = pdf.createLoadingTask(this.currentAttachmentsArr()[this.imageViewIndex]?.data)
        this.srcPDF.promise.then(file => {
          this.pagesPDF = file.numPages
        })
      }
    },
    encounterAttachments: {
      deep: true,
      handler() {
        if (this.downloadedAttachments.length > 0) {
          // Add any locally created attachments
          this.encounterAttachments.forEach(attachment => {
            if (attachment.updated && !this.downloadedAttachments.find(e => e.id === attachment.id)) {
              this.downloadedAttachments.push(attachment)
            }
          })

          // Remove attachment from downloadedAttachments if removed from encounterAttachments
          this.downloadedAttachments.forEach((attachment, attachmentIndex) => {
            const index = this.encounterAttachments.findIndex(e => e.id === attachment.id)

            // Only check locally created attachments via the 'updated' property.
            if (attachment.updated && index === -1) {
              this.downloadedAttachments.splice(attachmentIndex, 1)
            }
          })
        }
      },
    },
  },
  mounted() {
  },
  methods: {
    pageLoaded(page) {
      if (page === this.pagesPDF) {
        setTimeout(() => { // Gives it a second to render, reduces flashing
          const pages = document.querySelectorAll('.pdf-viewer')
          pages.forEach(el => {
            el.style.height = 'auto'
            el.style.opacity = '1'
          })
          this.loadingPDF = false
        }, 1000)
      }
    },
    formatDate(dateTime) {
      return this.$date(dateTime).format('M/D/YYYY')
    },
    formatDateTime(dateTime) {
      return this.$date(dateTime).format('M/D/YYYY h:mm:ss A')
    },
    attachmentCount(bucket) {
      if (!Array.isArray(this.encounterAttachments)) return false

      return this.encounterAttachments.reduce((count, item) => count + (item.attachment_type === bucket), 0)
    },
    isPDFType(mime) {
      return mime === 'application/pdf'
    },
    isImageType(mime) {
      return mime.substring(0, 5) === 'image'
    },
    mimeType(mime) {
      return mime.substring(mime.indexOf('/') + 1).toUpperCase()
    },
    viewAttachment(item) {
      if (item.data === 'placeholder') {
        if (this.isOnline) {
          this.downloadAttachment(item)
        } else {
          this.$store.dispatch('notify', { value: 'Please connect to the internet to view attachment.', color: 'error' })
        }
      } else if (this.isImageType(item.attachment_mime_type)) {
        const index = this.currentAttachmentsArr().findIndex(x => x.id === item.id)
        this.imageViewIndex = index
        this.imageTitle = item.attachment_filename
        this.imageDate = item.created
        this.isImage = true
        this.showOverlay = true
      } else if (this.isPDFType(item.attachment_mime_type)) {
        const index = this.currentAttachmentsArr().findIndex(x => x.id === item.id)
        this.imageViewIndex = index
        this.imageTitle = item.attachment_filename
        this.imageDate = item.created
        this.isPDF = true
        this.showOverlay = true
      } else {
        this.$store.dispatch('notify', { value: `Attachment must be an image or PDF to view, file type is: ${this.mimeType(item.attachment_mime_type)}` })
        this.closeOverlay()
      }
    },
    closeOverlay() {
      this.showOverlay = false
      this.imageViewIndex = null
      this.imageTitle = null
      this.imageDate = null
      this.isImage = false
      this.isPDF = false
    },
    deleteAttachment(index, item = null) {
      this.$root.confirm({
        title: 'Delete Attachment?',
        body: 'Are you sure you wish to delete this attachment?',
        cancel: 'No',
        confirm: 'Delete',
        confirmIcon: this.icons.mdiTrashCan,
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          this.closeOverlay()
          // eslint-disable-next-line no-param-reassign
          if (index === null) index = this.encounterAttachments.findIndex(x => x.id === item.id)
          if (index !== -1) {
            this.encounterAttachments.splice(index, 1)
          }
          this.updateAttachments()
        }
      })
    },
    attachmentTypeClicked(bucket) {
      this.dialogAttachmentType = bucket.value
    },
    selectAttachmentClicked() {
      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      // validate selected file
      if (e.target.files.length > 0) {
        const selectedFile = e.target.files[0]
        const allowPDF = this.$store.getters['baseData/bucketTypeFromValue'](this.dialogAttachmentType).pdf

        if (this.isImageType(selectedFile?.type) || (allowPDF && this.isPDFType(selectedFile?.type))) {
          this.file = selectedFile
          this.fileDescription = this.file.name.split('.').slice(0, -1).join('.')
          if (this.fileDescription === 'image') {
            this.fileDescription = `${this.$custom.toProperCase(this.dialogAttachmentType.slice(0, -1))} ${this.formatDate(Date.now())}`
          }
          this.imagePreview = URL.createObjectURL(selectedFile)
          this.attachment = {
            ...this.attachment,
            encounter_id: this.encounterId,
            patient_id: this.patientId,
            attachment_type: this.dialogAttachmentType,
            attachment_mime_type: this.file.type,
            attachment_filename: this.fileDescription,
            attachment_filesize: this.file.size,
            data: this.imagePreview,
          }
          this.isFileSelected = true
        } else {
          this.$refs.uploader.value = null
          this.resetUploadValues()
          this.$store.dispatch('notify', { value: `Attachment must be an image${allowPDF ? ' or PDF' : ''}, file type is: ${this.mimeType(selectedFile.type)}`, color: 'error' })
        }
      }
    },
    addFile() {
      this.isAddingFile = true
      const params = {
        attachment: {
          ...this.attachment,
          id: uuidv4(),
          updated: true,
          created: this.$custom.utcNow(),
          attachment_filename: this.fileDescription,
        },
        file: this.file,
      }

      const reader = new FileReader()
      reader.readAsDataURL(params.file)
      reader.addEventListener('load', () => {
        // For images, we scale and save as JPEG at 70% to reduce file size
        if (this.isImageType(params.attachment.attachment_mime_type)) {
          const img = document.createElement('img')
          img.src = reader.result
          img.addEventListener('load', e => {
            const canvas = document.createElement('canvas')
            const maxWidth = 768
            const scaleSize = maxWidth / e.target.width
            canvas.width = maxWidth
            canvas.height = e.target.height * scaleSize
            const ctx = canvas.getContext('2d')
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height)
            const src = ctx.canvas.toDataURL('image/jpeg', 0.7)
            params.attachment.attachment_width = canvas.width
            params.attachment.attachment_height = canvas.height
            params.attachment.attachment_mime_type = 'image/jpeg'
            params.attachment.attachment_filesize = src.length
            params.attachment.data = src
            this.encounterAttachments.push(params.attachment)
            this.updateAttachments()
            this.$store.dispatch('notify', { value: 'Attachment added successfully.', color: 'success' })
            this.resetUploadValues()
          })
        } else {
          // For other file types, we just dump the file
          params.attachment.data = reader.result
          this.encounterAttachments.push(params.attachment)
          this.updateAttachments()
          this.$store.dispatch('notify', { value: 'Attachment added successfully.', color: 'success' })
          this.resetUploadValues()
        }
      })
    },
    updateAttachments() {
      this.$store.commit('patients/updateAttachments', {
        id: this.patientId,
        attachments: this.encounterAttachments,
      })
    },
    downloadAttachment(item) {
      this.$store.dispatch('encounters/syncingModal', true)
      this.$store.dispatch('patients/getPatientAttachment', { patientId: this.patientId, attachmentId: item.id })
        .then(response => {
          if (response.status === 'Success') {
            if (this.downloadedAttachments.length < 1) {
              this.downloadedAttachments = this.$store.getters['patients/getById'](response.patient.id).all_attachments
            }

            // Replace placeholder data of index, with pulled base64 data
            response.patient.all_attachments.forEach(pulledAttachment => {
              const index = this.downloadedAttachments.findIndex(e => e.id === pulledAttachment.id)

              // splice and push to refresh user display
              this.downloadedAttachments.splice(index, 1)
              this.downloadedAttachments.push(pulledAttachment)
            })
            this.$store.dispatch('encounters/syncingModal', false)
            this.$store.dispatch('notify', { value: 'Attachments finished downloading!', color: 'success' })
          } else {
            this.$store.dispatch('encounters/syncingModal', false)
            this.$store.dispatch('notify', { value: 'Unable to download attachments', color: 'error' })
          }
        })
    },
    currentAttachmentsArr() {
      return this.downloadedAttachments.length > 0 ? this.downloadedAttachments : this.encounterAttachments
    },
    cancelUpload(outsideModalClick) {
      this.$root.confirm({
        title: 'Cancel Adding Attachment?',
        body: 'Are you sure you wish to cancel adding the attachment?',
        cancel: 'No',
        confirm: 'Yes',
      }).then(result => {
        if (result) {
          if (outsideModalClick) {
            this.closeModal()
          } else {
            this.resetUploadValues()
          }
        }
      })
    },
    resetUploadValues() {
      this.file = []
      this.fileDescription = null
      this.isFileSelected = false
      this.isAddingFile = false
      this.attachment = {
        attachment_filename: null,
        attachment_mime_type: null,
        attachment_filepath: '',
        attachment_width: 0,
        attachment_height: 0,
        data: null,
      }
    },
    closeModal() {
      this.modalState = false
      this.dialogAttachmentType = null
      this.$emit('update:attachmentModal', this.modalState)
      this.$emit('update:attachmentType', this.dialogAttachmentType)
      this.resetUploadValues()
    },
    clickOutside() {
      /* This causes this model to work differently than then rest of the app, so it was removed for consistency reasons
      if (this.isFileSelected) {
        this.cancelUpload(true)
      } else {
        this.closeModal()
      }
      */
    },
  },
}
</script>

<style lang="scss" scoped>
.attachments-modal {
  .table-preview {
    width: 100%;
    height: 75px;
    line-height: 75px;
  }
  .pdf-preview {
    max-width: 250px;
    max-height: 150px;
  }
}
.attachment-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: map-get($shades, 'white');
  border-radius: 0;
  overflow: hidden auto;
  .v-image {
    width: 100%;
    height: 91vh;
  }
  .pdf-viewer {
    width: 100%;
    height: 100vh; // If we don't specify a height, the PDF viewer will throw errors for some reason
    opacity: 0;
    border-bottom: 10px solid black;
    display: flex !important;
    align-items: center;
    transition: opacity 0.3s ease;
    &:last-of-type {
      margin-bottom: 95px;
    }
  }
  .controls {
    background-color: map-get($shades, 'white');
    flex-wrap: nowrap;
    z-index: 50;
    position: fixed;
    bottom: 12px;
    left: 12px;
    right: 12px;
    padding: 30px;
    > div {
      color: var(--v-accent-lighten3);
      padding: 0 10px;
      max-width: 40%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:first-of-type {
        padding-right: 0;
      }
    }
    > .synced {
      font-size: 1rem;
      color: rgba(94, 86, 105, 0.68) !important;
    }
  }
}
::v-deep {
  .image-preview {
    height: 46px;
    line-height: 46px;
    width: auto;
    border: 1px solid #aaa;
    border-radius: 5px;
    font-size: 1rem;
    &.mime-type {
      padding: 0 5px;
    }
  }
  .v-btn.v-btn--disabled {
    &.v-btn--active {
      background-color: var(--v-secondary-lighten3) !important;
      .v-btn__content {
        color: rgba(0, 0, 0, 0.42);
      }
    }
  }
  .v-data-table.disable {
    position: relative;
    .v-data-table__wrapper:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: map-get($shades, 'white');
      z-index: 10;
      opacity: 0.5;
    }
  }
}
</style>
