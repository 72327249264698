import Attachments from '@/components/notes/encounter/Attachments.vue'
import '@/components/patients/primitives'
import {
  mdiAlert, mdiAlertDecagram, mdiCancel, mdiCheck, mdiCheckDecagram, mdiClipboardCheck, mdiClose,
  mdiCloseCircle, mdiCloudCheckVariant, mdiContentSave, mdiContentSaveEdit, mdiCurrencyUsd, mdiDownload,
  mdiEyeOutline, mdiFileDocumentMultiple, mdiFileSign, mdiFileSync, mdiMarker, mdiMarkerCancel, mdiNoteEdit,
  mdiNotePlus, mdiPrinter, mdiTrashCan, mdiUndoVariant,
} from '@mdi/js'
import { mapGetters } from 'vuex'

export default {
  components: { Attachments },
  data() {
    return {
      formValid: false,
      highlightForm: false,
      encounterChanged: false,

      icons: {
        mdiAlert,
        mdiAlertDecagram,
        mdiCancel,
        mdiCheck,
        mdiCheckDecagram,
        mdiClipboardCheck,
        mdiClose,
        mdiCloseCircle,
        mdiCloudCheckVariant,
        mdiContentSave,
        mdiContentSaveEdit,
        mdiCurrencyUsd,
        mdiDownload,
        mdiEyeOutline,
        mdiFileDocumentMultiple,
        mdiFileSign,
        mdiFileSync,
        mdiMarker,
        mdiMarkerCancel,
        mdiNoteEdit,
        mdiNotePlus,
        mdiPrinter,
        mdiTrashCan,
        mdiUndoVariant,
      },
    }
  },
  computed: {
    ...mapGetters('route', ['lastRoute']),
    isFollowup() {
      return this.encounter?.visit_type !== 'New'
    },
  },
  mounted() {
    const loadEncounter = this.$store.getters['encounters/getById'](this.$route.query.id)
    const loadPatient = loadEncounter ? this.$store.getters['patients/getById'](loadEncounter.patient_id) : {}
    if (!this.$route.query.id || this.$custom.isEmpty(loadEncounter) || this.$custom.isEmpty(loadPatient)) {
      this.$store.dispatch('notify', { value: 'Encounter and/or patient not found!', color: 'error' })
      this.exit()
    } else {
      this.encounter = {
        ...this.$lodash.cloneDeep(this.encounter),
        ...this.$lodash.cloneDeep(loadEncounter),
      }
      this.encounter.visit_date = this.$date(this.encounter.visit_date).format('YYYY-MM-DD')
      this.patient = {
        ...this.$lodash.cloneDeep(this.patient),
        ...this.$lodash.cloneDeep(loadPatient),
        id: this.encounter.patient_id,
      }

      // Are there wound locations?
      if (this.patient.wounds?.length > 0) {
        let woundCount = 0
        let notSet = false

        // Make sure all the wound locations are numbered
        this.patient.wounds.forEach(wound => {
          if (wound.wound_number > 0) {
            woundCount = Math.max(woundCount, wound.wound_number)
          } else {
            notSet = true
          }
        })

        // If there's any wound locations not numbered, number them
        if (notSet) {
          woundCount += 1
          this.patient.wounds.forEach((wound, index) => {
            if (!wound.wound_number) {
              this.patient.wounds[index].wound_number = woundCount
              woundCount += 1
            }
          })

          // Update patient
          this.$store.commit('patients/updatePatient', this.patient)
        }
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.encounterChanged && to.name !== 'login') {
      this.$root.confirm({
        title: 'Discard Encounter Changes?',
        subTitle: 'Note: discarding changes will NOT affect comments made in offline mode',
        body: 'Are you sure you wish to cancel all changes to this encounter?',
        cancel: 'No',
        confirm: 'Discard',
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          next()
        }
      })
    } else {
      next()
    }
  },
  methods: {
    exit() {
      const isQuickEntry = (
        this.$route.query.quick
        || this.$route.query.quick === 'true'
        || this.$route.query.quickEntry
        || this.$route.query.quickEntry === 'true'
      ) && this.encounter.is_quick_entry
      const routeName = isQuickEntry ? 'quick-entry' : 'list-encounters'
      if (this.lastRoute.name === routeName) {
        this.$store.dispatch('route/back')
      } else {
        this.$router.push({ name: routeName })
      }
    },
  },
}
